
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { usePayoutCodeDealer } from "@/hooks/payment/mobile/usePayoutCodeDealer";
import {
  toThousandsAndTwoDecimal,
  toThousands,
} from "@/utils/payment/numberFormat";
import MButton from "@/views/Payment/Mobile/Components/MButton.vue";
import { HomeOutlined } from "@ant-design/icons-vue";
import MToTop from "@/views/Payment/Mobile/Components/MToTop.vue";
import MSegment from "@/views/Payment/Mobile/Components/MSegment.vue";
import MTitle from "@/views/Payment/Mobile/Components/MTitle.vue";
import ApproveSteps from "@/views/Approve/Mobile/components/ApproveSteps.vue";


import ApprovalModal from "@/views/Approve/Mobile/components/ApprovalModal.vue";
import MInfoModal from "@/views/Approve/Mobile/components/MInfoModal.vue";
import useManagementApprovalDetail from "@/hooks/approve/Mobile/useManagementApprovalDetail";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
        const route = useRoute()
        const { 
                programColumn,
                programTableData,
                approveList,
                actionType,
                modalVisible,
                modalMessage,
                approvalModalVisible,
                handleCommentsConfirm,
                loading
             } = useManagementApprovalDetail(route.query.batchId as string)
     


    const handleReject = () => {
      actionType.value = "rejected";
      approvalModalVisible.value = true;
    };
    const handleApprove = () => {
      actionType.value = "approved";
      approvalModalVisible.value = true;
    };

    // 吸顶
    const isFixed = ref<boolean>(false);
    let offsetTop = 0;
    const fixedRef = ref<null | HTMLElement>(null);
    const pageRef = ref<null | HTMLElement>(null);
    const toTopShow = ref<boolean>(false);
    const initHeight = () => {
      const scrollTop = pageRef.value ? pageRef.value?.scrollTop : 0;
      isFixed.value = scrollTop > offsetTop;
      toTopShow.value = scrollTop > 0;
    };
    onMounted(() => {
      offsetTop = fixedRef.value ? fixedRef.value?.offsetTop : offsetTop;
      pageRef.value?.addEventListener("scroll", initHeight);
    });
    onUnmounted(() => {
      pageRef.value?.removeEventListener("scroll", initHeight);
    });
    return {
    //   total,
    //   data,
    //   programYear,
      toThousandsAndTwoDecimal,
      toThousands,
      handleReject,
      handleApprove,
      actionType,
      approvalModalVisible,
      modalVisible,
      modalMessage,
      handleCommentsConfirm,
    //   toHomePage,
      isFixed,
      fixedRef,
      pageRef,
      toTopShow,
      approveList,


        programColumn,
        programTableData
    };
  },
  components: {
    MButton,
    HomeOutlined,
    MToTop,
    ApprovalModal,
    MInfoModal,
    MSegment,
    MTitle,
    ApproveSteps,
  },
});
