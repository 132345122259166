import { ref, reactive } from 'vue';
import { PendingApproveResponse, ApproveStatusResponseItem, BatchTrackingPackage, BatchTrackingQueryParams, ProgramTrackingQueryParams, PackageApproveRequestBodyItem } from "@/views/Approve/MgRoleApprove/PackageHome/types";
import { ApproveProgressResponse, PackageDetailResponse } from '@/views/Approve/MgRoleApprove/PackageDetail/types';
import { approvePackage, getApproveProgress, getMgPackageDetail, getCurrentUserApprovalStatus } from '@/API/approve';
import { useStore } from 'vuex';
import useFetch from "@/hooks/useFetch";

const useManagementApprovalDetail = (batchId: string) => {
    const {commit, state} = useStore()
    
    const _w = window.screen.width / 375
    const programColumn = ref([
        {
            title: 'No.',
            dataIndex: 'no',
            slots: { customRender: 'no' },
            width: _w * 50,
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            slots: { customRender: 'programName' },
            width: _w * 150,
        },
        {
            title: 'Total Budget',
            dataIndex: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            align: "right",
            width: _w * 100,
        }
        
    ])
    const initData: PackageDetailResponse  = {
        id: '',
        batchId: '',
        batchName: '',
        buName: '',
        status: '',
        nodes: 0,
        pbpBudget: '',
        cbuBudget: '',
        totalBudget: '',
        packageSaveActorId: '',
        packageSaveTime: '',
        packageSubbmitActorId: '',
        packageSubmitTime: '',
        programDtos: []
    }
    const { data: programTableData, refreshFetch } = useFetch<PackageDetailResponse>({ batchId: batchId }, initData, getMgPackageDetail);
    const approveList = reactive<ApproveProgressResponse>({
        id: '',
        buName: '',
        batchId: '',
        totalNodes: 0,
        approvaledNodes: '',
        status: '',
        nodeList: []
    });

    getApproveProgress({ params: { batchId: batchId } }).then(data => {
        Object.assign(approveList, data);
    })



    const approvalModalVisible = ref<boolean>(false);
    const modalVisible = ref<boolean>(false);
    const modalMessage = ref<string>('')
    const showMessage = (message: string) => {
        modalMessage.value = message
        modalVisible.value = true
    }


      const loading = ref(false)
      const actionType = ref<string>("approved");



      const handleCommentsConfirm = (comment: string, next: Function) => {
        const closeModal = () => {
          approvalModalVisible.value = false
          next()
        }
    
        loading.value = true
        const requestBody: PackageApproveRequestBodyItem[] = [
            {
                batchId: batchId,
                approvalSatus: actionType.value,
                content: comment
            }
        ];
        
        approvePackage(requestBody).then(data => {
            refreshFetch()
            closeModal()
            showMessage(actionType.value === 'approved' ?  'Approved Successfully!' : 'Rejected Successfully!')
            loading.value = false
        }).catch(() => {
            loading.value = false
            showMessage(actionType.value === 'approved' ?  'Approve Failed!' : 'Reject Failed!')
        })
      }
    
    
    return {
        programColumn,
        programTableData,
        approveList,
        handleCommentsConfirm,
        approvalModalVisible,
        modalVisible,
        modalMessage,
        actionType,
        loading
    }
}
export default useManagementApprovalDetail;