
    import { defineComponent, watch } from 'vue';
    import useModel from "@/hooks/useModel";
    // import { ApproveNode, Role } from "@/views/Approve/MgRoleApprove/PackageDetail/types";

    export default defineComponent({
        name: "ApproveSteps",
        props: {
            data: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        setup(props) {
            interface Role {
                rowName: string;
                pendingApproval?: string | null;
                approvalStatus: string;
                approvalTime: string;
                comment: string;
                userName: string;
            }
            interface ApproveNode {
                approvalStatus: string;
                sequenceIndex: string;
                roleDtoList: Role[];
            }
            //根据状态来获取对应的class
            const getStatusMappingClass = (data: ApproveNode, role: Role): string => {
                if (data.approvalStatus === 'approved') {
                    return 'approved';
                }
                if (data.approvalStatus === 'approvaling') {
                    if ((role.userName || role.approvalTime || role.comment) && role.approvalStatus === 'approved') {
                        return 'ongoing-approve-approved'

                    } else if ((role.userName || role.approvalTime || role.comment) && role.approvalStatus === 'approvaling' || role.pendingApproval) {
                        return 'ongoing-approve';
                    }
                }
                return '';
            }
            return {
                getStatusMappingClass
            }
        }
    })
