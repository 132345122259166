import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fd7c16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "steps-container m-t-8 bg-fff" }
const _hoisted_2 = { class: "steps-main" }
const _hoisted_3 = { class: "steps" }
const _hoisted_4 = { class: "step-item-content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "step-item",
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["step-item-icon", [item.approvalStatus === 'Approved' ? 'approved' : (item.approvalStatus === 'Approving' ? 'ongoing-approve' : '')]])
            }, _toDisplayString(item.sequenceIndex), 3),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.roleDtoList, (role, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "step-item-description",
                  key: index
                }, [
                  _createElementVNode("h2", {
                    class: _normalizeClass([_ctx.getStatusMappingClass(item, role), item.approvalStatus === 'Approved' ? 'approved' : (item.approvalStatus === 'Approving' ? 'ongoing-approve' : '')])
                  }, _toDisplayString(role.roleName), 3),
                  (role.flowUser)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("p", null, _toDisplayString(role.flowUser.approvalTime) + " " + _toDisplayString(role.flowUser.userName), 1),
                        (role.flowUser.comment)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Comments: " + _toDisplayString(role.flowUser.comment), 1))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (role.pendingApproval)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Pending Approve : " + _toDisplayString(role.pendingApproval), 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}